import React, { useState } from "react";
import "./PreOrderPage.css";
import {
  FaLock,
  FaCreditCard,
  FaBoxOpen,
  FaTruck,
  FaHeadset,
  FaShieldAlt,
  FaEnvelope,
  FaUser,
  FaPhoneAlt,
  FaLuggageCart,
  FaBox
} from "react-icons/fa";
import { BaseUrl } from "../components/BaseUrl";
import Banner from "../components/Banner";

const PreOrderPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    quantity: "",
    mobile: "",
    email: "",
    product: ""
  });
  const [mobileValid, setMobileValid] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile") {
      const mobileRegex = /^\+?[0-9]{10,14}$/;
      setMobileValid(mobileRegex.test(value));
    }
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!mobileValid) {
      document.getElementById("message").textContent =
        "Please enter a valid mobile number.";
      document.getElementById("message").style.color = "red";
      return;
    }

    document.getElementById("message").textContent = "Submitting...";
    document.getElementById("message").style.color = "blue";

    try {
      const response = await fetch(`${BaseUrl}/submitPreorder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();

      if (response.ok) {
        document.getElementById("message").textContent =
          data.message || "Your Pre-order has been sent successfully!";
        document.getElementById("message").style.color = "green";
        setFormData({
          name: "",
          quantity: "",
          mobile: "",
          email: "",
          product: ""
        });
      } else {
        throw new Error(data.message || "Failed to send your pre-order");
      }
    } catch (error) {
      console.error("Error:", error);
      document.getElementById("message").textContent =
        "An error occurred. Please try again later.";
      document.getElementById("message").style.color = "red";
    }
  };

  return (
    <div>
      <Banner
        description={
          "Be one of the first to access Eddievolt’s innovative mobile charging solutions. Preorder now and stay ahead with the latest in secure, sustainable tech."
        }
        heading={"Pre Order"}
      />

      <div className="preorder-page">
        <div className="shapes">
          <div className="shape shape1"></div>
          <div className="shape shape2"></div>
          <div className="shape shape3"></div>
        </div>
        <div className="additional-squares">
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
        </div>

        <section className="preorder-section">
          <div className="preorder-text">
            <h1 className="featured-heading">
              Pre-Order Your Charging Station
            </h1>
            <div>
              <h3>Charging Station Models</h3>
              <p>
                Explore our range of charging station units, from small-scale
                models for businesses to larger units for public spaces. Each
                model includes integrated advertising displays, adding value for
                businesses.
              </p>
            </div>
            <div>
              <h3>Special Pricing</h3>
              <p>
                Enjoy discounted rates for early pre-orders. Limited units
                available at this price.
              </p>
            </div>
          </div>
          <div className="preorder-images">
            <img
              src={require("../images/eddievolt-bus-station-hub.jpeg")}
              alt=""
              className="main-image"
            />
            {/* <div className="stacked-images">
        <img
          src={require("../images/prototype2.jpeg")}
          alt=""
          className="small-image"
        />
        <img
          src={require("../images/prototype3.jpeg")}
          alt=""
          className="small-image"
        />
      </div> */}
          </div>
        </section>
        <section className="why-preorder">
          <div className="preorders-header">
            <div className="section" id="why-invest">
              <div>
                <h2 className="featured-heading">Why Pre-Order?</h2>
                <div className="reasons">
                  <div className="reason">
                    <div className="titile-icon">
                      <div className="icon-wrapper">
                        <FaBoxOpen className="icon" />
                      </div>
                      <h3>Early Access</h3>
                    </div>

                    <p>
                      Be among the first to own an Eddievolt charging station
                      and take advantage of exclusive pricing and priority
                      support.
                    </p>
                  </div>
                  <div className="reason">
                    <div className="titile-icon">
                      <div className="icon-wrapper">
                        <FaShieldAlt className="icon" />
                      </div>
                      <h3>Support Innovation</h3>
                    </div>
                    <p>
                      Your pre-order helps us fund ongoing innovation and
                      expansion, allowing us to bring more sustainable solutions
                      to market.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <h2 className="featured-heading">Payment Options</h2>
                <div className="reasons">
                  <div className="reason">
                    <div className="titile-icon">
                      <div className="icon-wrapper">
                        <FaCreditCard className="icon" />
                      </div>
                      <h3>Flexible Plans</h3>
                    </div>

                    <p>
                      Choose from various payment methods, including full
                      upfront payment, installments, and financing options.
                    </p>
                  </div>
                  <div className="reason">
                    <div className="titile-icon">
                      <div className="icon-wrapper">
                        <FaLock className="icon" />
                      </div>
                      <h3>Secure Transactions</h3>
                    </div>
                    <p>
                      All transactions are processed securely, with support for
                      multiple payment platforms.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <h2 className="featured-heading">Pre Order now</h2>

        <div className="preorder-form">
          <div className="contact-form-container">
            <form className="contact-form" onSubmit={handleSubmit}>
              <label htmlFor="name">
                <FaUser className="contact-icon" />
                Full Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter your full name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
              <label htmlFor="mobile">
                {" "}
                <FaPhoneAlt className="contact-icon" />
                mobile
              </label>
              <input
                type="text"
                id="mobile"
                name="mobile"
                placeholder="What is your mobile number"
                value={formData.mobile}
                pattern="^\+?[0-9]{10,14}$"
                title="Please enter a valid mobile number (10-14 digits, optionally starting with +)"
                onChange={handleInputChange}
                style={{ borderColor: mobileValid }}
                required
              />
              <label htmlFor="email">
                <FaEnvelope className="contact-icon" />
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              <label htmlFor="product">
                <FaLuggageCart className="contact-icon" />
                Product
              </label>
              <input
                type="text"
                id="product"
                name="product"
                placeholder="Select product name"
                value={formData.product}
                onChange={handleInputChange}
                required
              />
              <label htmlFor="quantity">
                <FaBox className="contact-icon" />
                Quantity
              </label>
              <input
                type="number"
                id="quantity"
                name="quantity"
                placeholder="Enter quantity"
                value={formData.quantity}
                onChange={handleInputChange}
                required
              />
              <button type="submit">Send Order</button>
              <span id="message"></span>
            </form>
          </div>
        </div>
        <hr className="footer-divider" />

        <section className="faqs">
          <h2 className="featured-heading">Frequently Asked Questions</h2>
          <div className="faq-item">
            <div className="faq-iocn-title">
              <div className="icon-wrapper">
                <FaTruck className="icon" />
              </div>
              <h3>Delivery Times</h3>
            </div>
            <p>
              Expect delivery of your charging station within 4 weeks of
              pre-order.
            </p>
          </div>
          <div className="faq-item">
            <div className="faq-iocn-title">
              <div className="icon-wrapper">
                <FaHeadset className="icon" />
              </div>
              <h3>Support</h3>
            </div>
            <p>
              Dedicated customer service to assist with installation and any
              questions you may have.
            </p>
          </div>
          <div className="faq-item">
            <div className="faq-iocn-title">
              <div className="icon-wrapper">
                <FaShieldAlt className="icon" />
              </div>
              <h3>Warranty</h3>
            </div>
            <p>
              All charging stations come with a 5-year warranty, ensuring peace
              of mind.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PreOrderPage;

