import React from "react";
import Banner from "../components/Banner";
import {
  FaChartLine,
  FaShieldAlt,
  FaPiggyBank,
  FaDollarSign,
  FaLinkedin,
  FaEnvelope,
  FaFileDownload,
  FaSun
} from "react-icons/fa";
import "./Solutions.css";
import SolutionsSlider from "../components/SolutionsSlider";

export default function Solutions() {
  return (
    <div className="solutions">
      <Banner
        description={
          "Explore Eddievolt’s cutting-edge mobile charging solutions designed for a growing, mobile-dependent population. Our products are built to be secure, reliable, and eco-friendly."
        }
        heading={"Solutions"}
      />
      <main>
        <section className="ecs">
          <div>
            <h2 className="featured-heading" id="investors">
              Eddievolt Charging Stations
            </h2>
            <p>
              Our state-of-the-art charging stations offer secure, convenient,
              and sustainable charging for mobile devices. Powered by solar
              energy, these stations are strategically placed in high-traffic
              areas to ensure maximum accessibility and efficiency.
              Additionally, they feature advertising display screens that allow
              businesses to engage with a larger customer base.{" "}
            </p>
          </div>
          <div className="solutions-image-container">
            <img
              src={require("../images/boss_lady_trish.jpg")}
              alt="Step 5"
              className="responsive-image"
            />
            <img
              src={require("../images/eddievolt-bus-station-hub.jpeg")}
              alt="Step 5"
              className="responsive-image"
            />
          </div>
          <div className="solutions-image-container">
            <img
              src={require("../images/Model2.jpg")}
              alt="Step 5"
              className="responsive-image"
            />
            <img
              src={require("../images/YGEfe.jpg")}
              alt="Step 5"
              className="responsive-image"
            />
          </div>
        </section>
        <section className="tbe">
          <h2 className="featured-heading" id="investors">
            Technology Behind Eddievolt
          </h2>
          <div className="reasons">
            <div className="reason">
              <div className="titile-icon">
                <div className="icon-wrapper">
                  <FaSun className="icon" />
                </div>
                <h3>Solar Power</h3>
              </div>

              <p>
                Our charging stations harness the power of the sun, reducing
                environmental impact and ensuring consistent energy supply.
              </p>
            </div>
            <div className="reason">
              <div className="titile-icon">
                <div className="icon-wrapper">
                  <FaShieldAlt className="icon" />
                </div>
                <h3>Advanced Security</h3>
              </div>

              <p>
                Each station is equipped with advanced encryption and blockchain
                technology, providing users with top-level data protection.
              </p>
            </div>
            <div className="reason">
              <div className="titile-icon">
                <div className="icon-wrapper">
                  <FaChartLine className="icon" />
                </div>
                <h3>Integrated Advertising</h3>
              </div>
              <p>
                Our charging stations include display screens on the sides,
                offering advertisers a unique platform to reach customers they
                might not have otherwise engaged with. These ads complement
                traditional billboard advertising by providing focused, targeted
                exposure.
              </p>
            </div>
          </div>
        </section>
        <section className="hiw">
          <h2 className="featured-heading" id="investors">
            How It Works For Consumers
          </h2>
          <p className="hiw-p">
            Simply locate a charging station via our app, scan the QR code to
            show available sockets, log in to your account or make a quick
            registration, and top-up your account with integrated payment
            methods (mobile money, voucher code, or credit/debit cards). Plug in
            your adapter and switch on the socket. For the lockers, after
            switching on the power, you then switch the locker to open as well.
            Each socket or locker usage can only be controlled by the user who
            initiated the session. Take advantage of our workstations and free
            Wi-Fi while you wait.{" "}
          </p>
          <div className="solutions-slider">
            <SolutionsSlider />
          </div>
        </section>
        <div class="video-container">
          <iframe
            src="https://www.youtube.com/embed/AVs4Jq9i-ZQ"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>

        <section className="ecs">
          <h2 className="featured-heading" id="investors">
            How It Works For Businesses
          </h2>
          <p>
            Partner with Eddievolt to install charging stations at your
            location, attracting more customers and aligning your business with
            sustainable practices. Utilize the advertising displays to promote
            your products or services to a captive audience.
          </p>
        </section>
        <section className="fd">
          <h2 className="featured-heading" id="investors">
            Future Developments
          </h2>
          <div>
            <div className="top-feat">
              <div className="feature-item">
                <h3 className="feature-title">Expanding Reach</h3>
                <p className="feature-description">
                  Plans to introduce additional charging stations in new cities
                  across Ghana and beyond, increasing the reach and impact of
                  our advertising partners.
                </p>
              </div>
              <div className="feature-item">
                <h3 className="feature-title">New Features</h3>
                <p className="feature-description">
                  Upcoming enhancements include wireless charging, faster
                  charging speeds, and expanded connectivity options. We are
                  also exploring new ways to enhance the effectiveness of our
                  advertising displays.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

