import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SolutionsSlider.css";

export default function SolutionsSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true
  };

  return (
    <Slider {...settings}>
      <div className="slide">
        <div className="content-wrapper">
          <img
            src={require("../images/steps/step-1-QR-Code.png")}
            alt="Step 1"
            className="slide-image"
          />
          <div className="slide-content">
            <h3>Step 1: Scan The QR Code</h3>
            <p>
              Begin by scanning the QR code on the charging station using your
              mobile device to get started.
            </p>
          </div>
        </div>
      </div>
      <div className="slide">
        <div className="content-wrapper">
          <img
            src={require("../images/steps/step-2-pitch.png")}
            alt="Step 2"
            className="slide-image"
          />
          <div className="slide-content">
            <h3>Step 2: Choose A Charging Port</h3>
            <p>
              Select an available charging port that fits your device and get
              ready to power up.
            </p>
          </div>
        </div>
      </div>
      <div className="slide">
        <div className="content-wrapper">
          <img
            src={require("../images/steps/step-3-login-register-pitch.png")}
            alt="Step 3"
            className="slide-image"
          />
          <div className="slide-content">
            <h3>Step 3: Register/Login</h3>
            <p>
              Create an account or log in to your existing Eddievolt account for
              seamless access.
            </p>
          </div>
        </div>
      </div>
      <div className="slide">
        <div className="content-wrapper">
          <img
            src={require("../images/steps/step-4-charge-duration-pitch.png")}
            alt="Step 4"
            className="slide-image"
          />
          <div className="slide-content">
            <h3>Step 4: Select Charge Duration</h3>
            <p>
              Choose how long you'd like to charge your device based on your
              needs.
            </p>
          </div>
        </div>
      </div>
      <div className="slide">
        <div className="content-wrapper">
          <img
            src={require("../images/steps/step-5-topup-account-pitch.png")}
            alt="Step 5"
            className="slide-image"
          />
          <div className="slide-content">
            <h3>Step 5: Topup Account</h3>
            <p>
              Easily add funds to your Eddievolt account to pay for the charging
              session.
            </p>
          </div>
        </div>
      </div>
      <div className="slide">
        <div className="content-wrapper">
          <img
            src={require("../images/steps/step-6-start-charging-pitch.png")}
            alt="Step 5"
            className="slide-image"
          />
          <div className="slide-content">
            <h3>Step 6: Start Charging</h3>
            <p>
              Start charging your device and enjoy fast, reliable power on the
              go.
            </p>
          </div>
        </div>
      </div>
    </Slider>
  );
}

