import React from "react";
import "./Banner.css";
export default function Banner({ heading, description }) {
  return (
    <div className="banner-container">
      <div className="banner">
        <div className="additional-squares">
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
        </div>
        <h1>{heading}</h1>
        <p>{description}</p>
      </div>
    </div>
  );
}

