import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import Investors from "./pages/Investors";
import PreOrderPage from "./pages/PreOrder";
import Contact from "./pages/Contact";
import { Toaster } from "react-hot-toast";
import ScrollToTop from "./components/ScrollToTop";
import Solutions from "./pages/Solutions";

function App() {
  return (
    <Router>
      <div className="App">
        <ScrollToTop />
        <Toaster position="top-right" reverseOrder={false} />
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/Pre-Order" element={<PreOrderPage />} />
          <Route path="/investors" element={<Investors />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/solutions" element={<Solutions />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

