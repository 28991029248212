import React from "react";
import "./Investors.css";
import Banner from "../components/Banner";
import {
  FaChartLine,
  FaLightbulb,
  FaPiggyBank,
  FaDollarSign,
  FaLinkedin,
  FaEnvelope,
  FaFileDownload
} from "react-icons/fa";

export default function Investors() {
  return (
    <div>
      <Banner
        description={
          "Discover investment opportunities with Eddievolt as we drive growth in the mobile technology sector. Join us in creating sustainable, secure charging solutions for Ghana’s rapidly expanding mobile market."
        }
        heading={"Investors"}
      />
      <div className="investors-container">
        <div className="additional-squares">
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
        </div>
        <div className="wave"></div>
        <div className="Investor-header">
          <img src={require("../images/whyInvest.png")} alt="" />
          <div className="section" id="why-invest">
            <h2 className="featured-heading" id="investors">
              Why Invest in Eddievolt?
            </h2>
            <div className="reasons">
              <div className="reason">
                <div className="titile-icon">
                  <div className="icon-wrapper">
                    <FaChartLine className="icon" />
                  </div>
                  <h3>Market Opportunity</h3>
                </div>

                <p>
                  Africa’s mobile charging market is projected to grow to $5
                  billion by 2030. Eddievolt is poised to capture a significant
                  share of this market with our innovative, eco-friendly
                  charging stations that also serve as advertising platforms.
                </p>
              </div>
              <div className="reason">
                <div className="titile-icon">
                  <div className="icon-wrapper">
                    <FaDollarSign className="icon" />
                  </div>
                  <h3>Diverse Revenue Streams</h3>
                </div>
                <p>
                  Our business model includes charging session fees, direct
                  sales of charging stations, targeted advertising, and
                  strategic partnerships, ensuring multiple avenues for growth.
                </p>
              </div>
            </div>
            <div className="reason">
              <div className="titile-icon">
                <div className="icon-wrapper">
                  <FaLightbulb className="icon" />
                </div>
                <h3>Unique Value Proposition</h3>
              </div>
              <p>
                Our solar-powered charging stations offer unmatched security,
                convenience, and sustainability. With integrated advertising
                displays, we provide businesses with a powerful tool to reach a
                larger and more diverse audience. With over 80% of Ghanaians
                relying on smartphones, the demand for our solutions is clear
                and growing.
              </p>
            </div>
          </div>
        </div>

        <div className="funding section" id="current-funding">
          <div className="heading-with-icon">
            <h2 className="featured-heading">Current Funding Round</h2>
            <p>
              We are raising $200,000 in pre-seed funding to scale our
              operations and deploy 50 charging stations across Ghana’s busiest
              urban areas. This funding will accelerate our commercial launch
              and enable us to meet growing demand, while also expanding our
              advertising network.
            </p>
          </div>
          <FaPiggyBank className="funding-icon" />
        </div>

        <div className="section" id="use-of-funds">
          <h2 className="featured-heading">Use of Funds</h2>
          <div className="feature-list">
            <div className="top-feat">
              <div className="feature-item">
                <h3 className="feature-title">Deployment</h3>
                <p className="feature-description">
                  Installing 50 charging stations in strategic locations across
                  Ghana, each featuring integrated advertising displays.
                </p>
              </div>
              <div className="feature-item">
                <h3 className="feature-title">Team Expansion</h3>

                <p className="feature-description">
                  Hiring key talent to support product development, sales, and
                  marketing.
                </p>
              </div>
              <div className="feature-item">
                <h3 className="feature-title">Marketing</h3>

                <p className="feature-description">
                  Launching targeted campaigns to drive awareness and adoption
                  of Eddievolt charging stations, and to attract advertising
                  partners.
                </p>
              </div>
            </div>
          </div>
        </div>
        <h2 className="featured-heading">Meet Our Team</h2>

        <div className="team-section" id="meet-team">
          <div className="team-members">
            <div className="team-member">
              <div className="image-container">
                <img
                  src={require("../images/Edward.png")}
                  alt="Edward Oware Ohemeng"
                  className="team-image"
                />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/in/edward-ohemeng24/"
                  className="linkedin-icon"
                >
                  <FaLinkedin />
                </a>
              </div>
              <h3>Edward Oware Ohemeng</h3>
              <h4 className="position">CEO</h4>
              <p>
                Leading Eddievolt with a vision for innovation and
                sustainability.
              </p>
            </div>
            <div className="team-member">
              <div className="image-container">
                <img
                  src={require("../images/Francis.png")}
                  alt="Francis Zaato"
                  className="team-image"
                />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/in/francis-zaato-agia-pmp-443802134/"
                  className="linkedin-icon"
                >
                  <FaLinkedin />
                </a>
              </div>
              <h3>Francis Zaato</h3>
              <h4 className="position">COO</h4>
              <p>
                Expert in product design and branding, ensuring Eddievolt’s
                solutions meet market needs.
              </p>
            </div>
            <div className="team-member">
              <div className="image-container">
                <img
                  src={require("../images/Surabhi.png")}
                  alt="Surabhi Boob"
                  className="team-image"
                />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/in/surabhi-boob-62948862/"
                  className="linkedin-icon"
                >
                  <FaLinkedin />
                </a>
              </div>
              <h3>Surabhi Boob</h3>
              <h4 className="position">CTO</h4>
              <p>
                Driving technical innovation with expertise in software, AI, and
                embedded systems.
              </p>
            </div>
          </div>
        </div>

        <h2 className="investor-relationship-heading featured-heading">
          Investor Relations
        </h2>
        <div className="investor-relations-container">
          <div className="investor-relations-card">
            <div className="titile-icon">
              <div className="icon-wrapper">
                <FaChartLine className="investor-icon" />
              </div>
              <h2>Regular Updates</h2>
            </div>
            <p>
              Stay informed with monthly updates on our progress, milestones,
              and financial performance.
            </p>
          </div>
          <div className="investor-relations-card">
            <div className="titile-icon">
              <div className="icon-wrapper">
                <FaEnvelope className="investor-icon" />
              </div>
              <h2>Contact Us</h2>
            </div>
            <p>
              Reach out directly to discuss investment opportunities and how you
              can be part of Eddievolt’s success.
            </p>
          </div>
          <div className="download-pitch-deck">
            <a
              href="https://docs.google.com/presentation/d/1V2p2JdDw6_AaOCaBgbm7Zq-g4NJqu77TZyRp4VBJA88/edit?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
              className="pitch-deck-button"
            >
              <FaFileDownload className="download-icon" />
              Download Our Pitch Deck
            </a>
          </div>
        </div>
        <div className="shapes">
          <div className="shape shape1"></div>
          <div className="shape shape2"></div>
          <div className="shape shape3"></div>
        </div>
      </div>
    </div>
  );
}

