import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaYoutube,
  FaInstagram,
  FaTwitter,
  FaPhoneAlt,
  FaMapMarkerAlt,
  FaEnvelope,
  FaLinkedin
} from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-columns">
        <div className="footer-column">
          <img
            src={require("../images/logoEdivolt.png")}
            alt="Eddievolt"
            className="footer-logo"
          />
          <div className="contact-info">
            <p>
              Secure and Eco-Friendly Charging Solutions for Ghana and Beyond
            </p>
            <p className="contact-item">
              <FaPhoneAlt className="contact-icon" />
              +233 241310753 / 545160073
            </p>
            <p className="contact-item">
              <FaMapMarkerAlt className="contact-icon" />
              Spintex Road Adjacent Canwest Limited, Accra, Ghana.
            </p>
            <p className="contact-item">
              <FaEnvelope className="contact-icon" />
              info@eddievolt.com
            </p>
          </div>
        </div>

        <div className="footer-nav-list-container footer-column">
          <h4 className="useful-links-head">Useful Links</h4>
          <div className="Footer-navlist">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>

              <li>
                <Link to="/investors">Investors</Link>
              </li>
            </ul>
            <ul>
              {/* <li>
                <Link to="#">Solutions</Link>
              </li> */}
              {/* <li>
                <Link to="#">Blog</Link>
              </li> */}
              <li>
                <Link to="/contact">Contacts</Link>
              </li>
              <li>
                <Link to="/Pre-order">Pre-Order</Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="footer-column">
          <h4>Follow Us</h4>
          <div className="social-icons">
            <Link
              to="https://www.linkedin.com/company/eddievolt/"
              className="social-icon"
            >
              <FaLinkedin />
            </Link>
            <Link
              to="https://www.youtube.com/@eddievolt"
              className="social-icon"
            >
              <FaYoutube />
            </Link>
            <Link
              to="https://www.instagram.com/eddievolt"
              className="social-icon"
            >
              <FaInstagram />
            </Link>
            <Link to="https://twitter.com/eddievolt_" className="social-icon">
              <FaTwitter />
            </Link>
            <Link
              to="https://www.facebook.com/eddievoltglobal"
              className="social-icon"
            >
              <FaFacebook />
            </Link>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <hr className="footer-divider" />
        <p>Copyright © 2024 Eddievolt | All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;

