import React from "react";
import "./About.css";
import Shapes from "../components/Shape";
import Timeline from "../components/Timeline";
import {
  FaEye,
  FaBullseye,
  FaLaptopCode,
  FaMapMarkedAlt,
  FaLinkedin
} from "react-icons/fa";
import { MdOutlineDesignServices } from "react-icons/md";
import Banner from "../components/Banner";

const About = () => {
  return (
    <div>
      <Banner
        description={
          "Learn more about Eddievolt’s mission to close the digital divide in Ghana with innovative, reliable charging solutions for mobile users."
        }
        heading={"About Us"}
      />
      <div className="about-us">
        <div className="about-content">
          <div className="featured-section">
            <Shapes />
            <div className="shapes-container">
              <div className="shape rectangle-1"></div>
              <div className="shape rectangle-2"></div>
              <div className="shape rectangle-3"></div>
              <div className="shape rectangle-4"></div>
              <div className="shape rectangle-5"></div>
              <div className="shape rectangle-6"></div>
              <div className="shape rectangle-7"></div>
              <div className="shape circle-1"></div>
              <div className="shape circle-2"></div>
              <div className="shape triangle-1"></div>
            </div>
            <section className="preorder-section">
              <div className="preorder-text">
                <h1 className="featured-heading">Our Story</h1>
                <p>
                  Eddievolt was born out of a need to bridge the digital divide
                  in Ghana, where mobile devices are essential for everyday
                  life, but reliable and secure charging options are scarce.
                  Founded in 2022 by a team of passionate experts in clean-tech,
                  project management, and business development, Eddievolt is
                  committed to providing innovative solutions for the rapidly
                  growing mobile-dependent population.
                </p>
              </div>
              <div className="preorder-images">
                <img
                  src={require("../images/ourstory1.png")}
                  alt=""
                  className="main-image"
                />
              </div>
              {/* <div className="about-images">
              <div className="stacked-images">
                <img
                  src={require("../images/ourstory2.png")}
                  alt=""
                  className="small-image"
                />
                <img
                  src={require("../images/ourstory4.jpg")}
                  alt=""
                  className="small-image"
                />
                <img
                  src={require("../images/ourstory3.png")}
                  alt=""
                  className="small-image"
                />
              </div>
            </div> */}
            </section>
          </div>
          <h2 className="investor-relationship-heading featured-heading">
            Our Mission and Vision
          </h2>
          <div className="investor-relations-container">
            <div className="investor-relations-card">
              <div className="titile-icon">
                <div className="icon-wrapper">
                  <FaEye className="investor-icon" />
                </div>
                <h2>Vision</h2>
              </div>
              <p>
                To become the leading provider of eco-friendly charging
                solutions across Africa, driving innovation and sustainability
                in mobile technology while creating new revenue streams for
                businesses through strategic advertising.
              </p>
            </div>
            <div className="investor-relations-card">
              <div className="titile-icon">
                <div className="icon-wrapper">
                  <FaBullseye className="investor-icon" />
                </div>
                <h2>Mission</h2>
              </div>
              <p>
                To empower communities by providing reliable, secure, and
                sustainable charging solutions that enhance connectivity while
                offering impactful advertising opportunities.
              </p>
            </div>
          </div>
          <h2 className="featured-heading" id="investors">
            The Eddievolt Difference
          </h2>
          <div className="Investor-header">
            <img src={require("../images/difference.png")} alt="" />
            <div className="section" id="why-invest">
              <div className="reasons">
                <div className="reason">
                  <div className="titile-icon">
                    <div className="icon-wrapper">
                      <FaLaptopCode className="investor-icon" />
                    </div>
                    <h3>Technology</h3>
                  </div>

                  <p>
                    Eddievolt charging stations are powered by solar energy,
                    equipped with advanced security features, and designed for
                    durability and convenience. Our stations also feature
                    advertising display screens that offer businesses a unique
                    way to reach a larger audience.
                  </p>
                </div>
                <div className="reason">
                  <div className="titile-icon">
                    <div className="icon-wrapper">
                      <FaMapMarkedAlt className="investor-icon" />
                    </div>
                    <h3>Strategic Placement</h3>
                  </div>
                  <p>
                    Our business model includes charging session fees, direct
                    sales of charging stations, targeted advertising, and
                    strategic partnerships, ensuring multiple avenues for
                    growth.
                  </p>
                </div>
              </div>
              <div className="reason">
                <div className="titile-icon">
                  <div className="icon-wrapper">
                    <MdOutlineDesignServices className="investor-icon" />
                  </div>
                  <h3>User-Centric Design</h3>
                </div>
                <p>
                  We prioritize user experience, providing not just charging but
                  also workstations and Wi-Fi, making our stations a hub for
                  productivity on the go. Additionally, the integrated
                  advertising displays provide businesses with valuable exposure
                  to a captive audience.
                </p>
              </div>
            </div>
          </div>
          <h2 className="featured-heading">Meet Our Team</h2>

          <div className="team-section" id="meet-team">
            <div className="team-members">
              <div className="team-member">
                <div className="image-container">
                  <img
                    src={require("../images/Edward.png")}
                    alt="Edward Oware Ohemeng"
                    className="team-image"
                  />
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/in/edward-ohemeng24/"
                    className="linkedin-icon"
                  >
                    <FaLinkedin />
                  </a>
                </div>
                <h3>Edward Oware Ohemeng</h3>
                <h4 className="position">CEO</h4>
                <p>
                  Leading Eddievolt with a vision for innovation and
                  sustainability.
                </p>
              </div>
              <div className="team-member">
                <div className="image-container">
                  <img
                    src={require("../images/Francis.png")}
                    alt="Francis Zaato"
                    className="team-image"
                  />
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/in/francis-zaato-agia-pmp-443802134/"
                    className="linkedin-icon"
                  >
                    <FaLinkedin />
                  </a>
                </div>
                <h3>Francis Zaato</h3>
                <h4 className="position">COO</h4>
                <p>
                  Expert in product design and branding, ensuring Eddievolt’s
                  solutions meet market needs.
                </p>
              </div>
              <div className="team-member">
                <div className="image-container">
                  <img
                    src={require("../images/Surabhi.png")}
                    alt="Surabhi Boob"
                    className="team-image"
                  />
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/in/surabhi-boob-62948862/"
                    className="linkedin-icon"
                  >
                    <FaLinkedin />
                  </a>
                </div>
                <h3>Surabhi Boob</h3>
                <h4 className="position">CTO</h4>
                <p>
                  Driving technical innovation with expertise in software, AI,
                  and embedded systems.
                </p>
              </div>
            </div>
          </div>
          <h2 className="featured-heading">Milestones</h2>

          <Timeline />
        </div>
      </div>
    </div>
  );
};

export default About;

